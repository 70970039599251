@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading {
  font-size: 48px;
  text-align: center;
  color: transparent;
  -webkit-text-stroke: 0.5px #ddd;
  text-transform: uppercase;
  font-weight: 800;
}
.heading span {
  -webkit-text-stroke: 0.5px #60a5fa;
}

/* blob */
.morshed-blob {
  background-image: url(https://i.ibb.co/s5j4YDS/Morshed-PP.jpg);
  width: 380px;
  height: 380px;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center center;
  margin: 20px;
  box-shadow: 0 5px 5px 5px rgba(13, 110, 253, 0.2);
  animation: animate 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}

@keyframes animate {
  0%,
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
}

/* Media Quary */
@media screen and (max-width: 576px) {
  .morshed-blob {
    width: 280px;
    height: 280px;
  }
  .heading {
    font-size: 36px;
  }
}

/* background starts */
.container {
  width: 100%;
  height: 100%;
  --color: rgba(114, 114, 114, 0.3);
  background-color: #191a1a;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      var(--color) 25%,
      var(--color) 26%,
      transparent 27%,
      transparent 74%,
      var(--color) 75%,
      var(--color) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      var(--color) 25%,
      var(--color) 26%,
      transparent 27%,
      transparent 74%,
      var(--color) 75%,
      var(--color) 76%,
      transparent 77%,
      transparent
    );
  background-size: 55px 55px;
}
/* background ends */

/* loader starts */
/* HTML: <div class="loader"></div> */
/* .loader {
  width: 100px;
  height: 60px;
  display: flex;
  animation: l12-0 2s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  flex: 4;
  background: radial-gradient(at 50% 20%, #0000, #000a) bottom left/20px 20px
      repeat-x,
    linear-gradient(red 0 0) bottom/100% 20px no-repeat #ddd;
  -webkit-mask: repeating-linear-gradient(90deg, #000 0 4px, #0000 0 20px) 8px 0,
    radial-gradient(farthest-side, #000 90%, #0000) left bottom/20px 20px
      repeat-x;
}
.loader::after {
  flex: 1;
  transform-origin: top;
  animation: l12-1 1s cubic-bezier(0, 20, 1, 20) infinite;
}
@keyframes l12-0 {
  0%,
  49.9% {
    transform: scaleX(1);
  }
  50%,
  100% {
    transform: scaleX(-1);
  }
}
@keyframes l12-1 {
  100% {
    transform: rotate(-2deg);
  }
}
loader ends */

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #514b82;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 70deg,
      #0000 71deg 90deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l5 1s infinite;
}
@keyframes l5 {
  to {
    transform: rotate(0.5turn);
  }
}
